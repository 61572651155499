<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        v{{ version }} - {{ $t('Release Notes') }} -
        {{ $moment(releaseDate, 'DD-MM-YYYY').format('Do MMMM YYYY') }}
      </p>
    </header>
    <section class="modal-card-body">
      <div style="margin-left: 1em; margin-right: 1em">
        {{ $t('Welcome to version') }}
        <span class="has-text-weight-bold">v{{ version }}</span>
        {{
          $t(
            'of the Leadent Gateway Portal. We regularly release changes to add features and fix bugs. In this release we have added',
          )
        }}:
        <br />

        <p class="is-size-5 has-text-weight-bold mt-05">New Features</p>
        <ul>
          <li v-for="item in features" :key="item">
            {{ item }}
          </li>
        </ul>
        <p class="is-size-5 has-text-weight-bold mt-05">Improvements</p>
        <ul>
          <li v-for="item in improvements" :key="item">
            {{ item }}
          </li>
        </ul>
        <p class="is-size-5 has-text-weight-bold mt-05">Bug Fixes</p>
        <ul>
          <li v-for="item in bugFixes" :key="item">
            {{ item }}
          </li>
        </ul>
      </div>
      <br />
      <b-collapse :open="false">
        <a slot="trigger" @click="toggleMessage = !toggleMessage">
          {{
            toggleMessage
              ? $t('Hide older release notes')
              : $t('Show older release notes')
          }}
        </a>
        <div
          v-for="release in oldReleases"
          :key="release.version"
          class="notification"
        >
          <p
            v-if="release.date"
            class="is-size-6 has-text-grey has-text-weight-bold"
          >
            Release Date: {{ release.date }}
          </p>
          <p class="is-size-6 has-text-grey has-text-weight-bold">
            v{{ release.version }}
          </p>
          <p class="is-size-6 has-text-weight-bold">Improvements</p>
          <ul>
            <li v-for="item in release.improvements" :key="item">
              {{ item }}
            </li>
          </ul>
          <p class="is-size-6 has-text-weight-bold">New Features</p>
          <ul>
            <li v-for="item in release.features" :key="item">
              {{ item }}
            </li>
          </ul>
          <p class="is-size-6 has-text-weight-bold">Bug Fixes</p>
          <ul>
            <li v-for="item in release.bugFixes" :key="item">
              {{ item }}
            </li>
          </ul>
        </div>
      </b-collapse>
    </section>

    <footer class="modal-card-foot">
      <b-button type="is-primary" @click="$parent.close()">
        {{ $t('Close') }}
      </b-button>
    </footer>
  </div>
</template>

<script>
import releaseNoteData from '../../../data/release-notes.json';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  data() {
    return {
      toggleMessage: false,
      currentRelease: {},
      oldReleases: [],
    };
  },
  computed: {
    bugFixes() {
      return this.currentRelease.bugFixes;
    },
    improvements() {
      return this.currentRelease.improvements;
    },
    features() {
      return this.currentRelease.features;
    },
    releaseDate() {
      return this.currentRelease.date;
    },
  },
  mounted() {
    this.parseReleases();
  },
  methods: {
    parseReleases() {
      for (let i = 0; i < releaseNoteData.length; i++) {
        if (releaseNoteData[i].version === this.version) {
          this.currentRelease = releaseNoteData[i];
        } else {
          this.oldReleases.push(releaseNoteData[i]);
        }
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../sass/leadent.scss';

li {
  list-style-type: decimal !important;
}
li {
  color: $primary;
}

.mt-05 {
  margin-top: 0.5em;
}
</style>
